@import-normalize; /* bring in normalize.css styles */


/* rest of app styles */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list-map{
  padding: 0;
  list-style-type: none;
}

.list-map li:before { 
  content:''; 
  display:inline-block; 
  height:1em; 
  width:1em; 
  background-image:url('pointer-map.svg'); 
  background-size:contain; 
  background-repeat:no-repeat; 
  padding-left: 2em; 
  }