// Override default variables before the import
$body-bg: rgb(255, 255, 255);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.img-thumbnail {
    border: none;
    max-width: 150px;
    width:100%;
  }

.img-thumbnail-small {
  border: none;
  max-width: 80px;
  width:100%;
}